import React, { useState, useEffect } from "react";
import "./Navpages.css";
import { Container, Nav, Navbar, NavDropdown } from "react-bootstrap";

const Navpages = ({ logos, url, navbarData }) => {



    const [scrollPosition, setScrollPosition] = useState(0);
    const [navbarClass, setNavbarClass] = useState('');
  
    const updateNavbar = () => {
      const windowUrl = window.location.pathname;
      if (windowUrl === '/' || windowUrl === '/home') {
        if (scrollPosition > 30) {
          setNavbarClass('nav-pages');
        } else {
          setNavbarClass('nav-home');
        }
      } else {
        setNavbarClass('nav-pages');
      }
    };
  
    useEffect(() => {
      const handleScroll = () => {
        setScrollPosition(window.pageYOffset);
      };
  
      const handleLocationChange = () => {
        updateNavbar();
      };
  
      window.addEventListener('scroll', handleScroll);
      window.addEventListener('popstate', handleLocationChange);
  
      return () => {
        window.removeEventListener('scroll', handleScroll);
        window.removeEventListener('popstate', handleLocationChange);
      };
    }, []);
  
    useEffect(() => {
      updateNavbar();
    }, [scrollPosition]);
  
    const renderNavLinks = (items) => {
      return items.map((item) => {
        if (item.children && item.children.length > 0) {
          return (
            <NavDropdown title={item.name} id={`nav-dropdown-${item.id}`} key={item.id}>
              {item.children.map((child) => (
                <NavDropdown.Item key={child.id} href={child.url}>
                  {child.name}
                </NavDropdown.Item>
              ))}
            </NavDropdown>
          );
        } else {
          return (
            <Nav.Link key={item.id} href={`/${item.url}`}>
              {item.name}
            </Nav.Link>
          );
        }
      });
    };



    return (
        <Navbar expand="lg" className={`${navbarClass}`}>
            <Container>
                <Navbar.Brand href="/home">
                    <img className="nav-logo" src={url + logos.logo} />
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="navbarScroll" />
                <Navbar.Collapse id="navbarScroll">
                    <Nav
                        className="me-auto"
                    >
                     {renderNavLinks(navbarData)}
                    </Nav>
                    <div className='nav-language'>
                        <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M15 27.5C21.9036 27.5 27.5 21.9036 27.5 15C27.5 8.09644 21.9036 2.5 15 2.5C8.09644 2.5 2.5 8.09644 2.5 15C2.5 21.9036 8.09644 27.5 15 27.5Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M2.5 15H27.5" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M15 27.5C17.7614 27.5 20 21.9036 20 15C20 8.09644 17.7614 2.5 15 2.5C12.2386 2.5 10 8.09644 10 15C10 21.9036 12.2386 27.5 15 27.5Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M6.16119 6.33887C8.42325 8.60093 11.5482 10.0001 15 10.0001C18.4518 10.0001 21.5768 8.60093 23.8389 6.33887" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M23.8389 23.6612C21.5768 21.3991 18.4518 20 15 20C11.5482 20 8.42325 21.3991 6.16119 23.6612" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                        <a>en</a>
                        <button className='nav-search'>
                            <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g clip-path="url(#clip0_184_25)">
                                    <path d="M18.347 16.1623C19.8599 14.0977 20.5376 11.5381 20.2443 8.99534C19.9511 6.45262 18.7085 4.11438 16.7653 2.44843C14.8221 0.78248 12.3216 -0.0883286 9.7639 0.0102232C7.20622 0.108775 4.78007 1.16942 2.97082 2.97996C1.16158 4.7905 0.10267 7.21741 0.00594795 9.77516C-0.0907736 12.3329 0.781824 14.8329 2.44917 16.7749C4.11651 18.7169 6.45563 19.9577 8.99856 20.2491C11.5415 20.5406 14.1007 19.8611 16.1642 18.3467H16.1626C16.2095 18.4092 16.2595 18.4685 16.3157 18.5264L22.3313 24.542C22.6243 24.8352 23.0218 25 23.4363 25.0001C23.8507 25.0002 24.2483 24.8357 24.5415 24.5428C24.8347 24.2498 24.9995 23.8523 24.9996 23.4378C24.9998 23.0234 24.8353 22.6258 24.5423 22.3326L18.5267 16.317C18.4708 16.2604 18.4107 16.2098 18.347 16.1623ZM18.7501 10.156C18.7501 11.2846 18.5278 12.4021 18.0959 13.4447C17.6641 14.4874 17.031 15.4347 16.233 16.2327C15.435 17.0307 14.4877 17.6638 13.445 18.0956C12.4024 18.5275 11.2849 18.7498 10.1563 18.7498C9.02779 18.7498 7.9103 18.5275 6.86766 18.0956C5.82501 17.6638 4.87765 17.0307 4.07964 16.2327C3.28164 15.4347 2.64863 14.4874 2.21675 13.4447C1.78487 12.4021 1.56259 11.2846 1.56259 10.156C1.56259 7.87684 2.468 5.69098 4.07964 4.07934C5.69128 2.4677 7.87714 1.56229 10.1563 1.56229C12.4355 1.56229 14.6214 2.4677 16.233 4.07934C17.8447 5.69098 18.7501 7.87684 18.7501 10.156Z" fill="white" />
                                </g>
                                <defs>
                                    <clipPath id="clip0_184_25">
                                        <rect width="25" height="25" fill="white" />
                                    </clipPath>
                                </defs>
                            </svg>
                        </button>
                    </div>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
};

export default Navpages;
