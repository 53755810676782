import React from 'react'
import "./Types.css"
import { Container, Row, Col } from 'react-bootstrap'
import { TypeCard, Paragraph, Title, TitleSection } from '../../../../components/index'


const Types = ({ contentData, url, translationsData }) => {

    const typesData = contentData.education_types || [];

  return (
    <div>
        <Container className='section-border section-margin'>
            <Title title = {translationsData.apply_now} />
            <TitleSection title = {contentData.type_of_education_title} /> 
            <Paragraph
                para = {contentData.type_of_education_description}
            />
            
            <Row className="types-cards">

                {
                    typesData.slice(0, 8).map((item, index) => (
                        <Col lg={3} md={6} key={index}>
                            <a href={`/educations/${item.id}`}>
                                <TypeCard
                                    img={url + item.image}
                                    title={item.name}
                                    para={item.description_short}
                                />
                            </a>
                           
                        </Col>
                    ))
                }
  
            </Row>
            <div className='mt-5' style={{ display: 'flex', justifyContent: 'center', width: '100%'}}>
                <a className='show-all' href='/educations'>
                    Show All
                </a>
            </div>
         
        </Container>
        <div className='section-margin'></div>
    </div>
  )
}

export default Types