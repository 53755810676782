import React from 'react'
import "./StudentsDetails.css"
import { Container, Row, Col } from 'react-bootstrap'
import { Header } from '../../components'
import loading from "../../assets/images/svg/photos-icon.psd.svg"

const StudentsDetails = ({contentData, url, translationsData, innerPageData}) => {
    return (
        <>
        <Header title={innerPageData.name} image={url + contentData.image_top} />
        <Container className="details-container">
            <Row>
            <Col lg={6}>
                <h1 className="details-title">{contentData.name}</h1>
                <p
                className="details-list"
                dangerouslySetInnerHTML={{ __html: contentData.description }}
                ></p>
            </Col>
            <Col lg={1}></Col>
            <Col lg={5}>
                <div  className="details-image">
                <img
                    className="details-img"
                    src={contentData.image ? url + contentData.image : loading}
                />
                </div>
            </Col>
            </Row>
        </Container>
    </>
    )
}

export default StudentsDetails