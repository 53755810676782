import React from 'react'
import "./Services.css"
import { Paragraph, Title, TitleSection, Card } from "../../../../components/index"
import { Container, Row, Col } from 'react-bootstrap'
import loading from "../../../../assets/images/svg/photos-icon.psd.svg"

const Services = ({ contentData, translationsData, url }) => {

    const servicesData = contentData.services || [];

    return (
        <div className='sevices-home section-margin'>
            <Container>
                <Row>
                    <Col xl={6}>
                        <Title title = {translationsData.what_do_we_do} />
                        <TitleSection title = {contentData.our_services_title}  />
                    </Col>
                </Row>
                <Row>
                    <Paragraph para = {contentData.our_services_description} />
                </Row>
                <Row className="cards">
                    {
                        servicesData.map((item, index) => (
                            <Col md={6} key={index}>
                                <a href={`/services/${item.id}`}>
                                    <Card
                                        image = {item.image ? url + item.image : loading} 
                                        title = {item.name} 
                                        para = {item.description_short}
                                    />
                                </a> 
                            </Col>
                        ))
                    }
                </Row>
            </Container>
        </div>
    )
}

export default Services
