import { useState, useEffect } from 'react';
import './App.css';
import { Home, Services, Students, Countries, About, Contactus, CountriesDetails, StudentsDetails, ServicesDetails, Educations, EducationsDetails } from './pages/index';
import { Footer, Navpages } from './layouts/index';
import { Route, Routes, BrowserRouter } from 'react-router-dom';
import AOS from 'aos';
import 'aos/dist/aos.css';



function App() {
  
  const [loading, setLoading] = useState(true);
  const url = 'https://backend.brightmindedu-us.com';
  const apiVersion = '/api/v1';
  let limit = 4;
  const pageUrl = window.location.pathname;

  const lastWordIndex = pageUrl.lastIndexOf('/') + 1;
  let pageName = pageUrl.substr(lastWordIndex);
  const [navbarData, setNavbarData] = useState([]);
  const [footerData, setFooterData] = useState({});
  const [logos, setLogos] = useState({});
  const [contentData, setContentData] = useState({});
  const [innerPageData, setInnerPageData] = useState({});
  const [translationsData, setTranslationsData] = useState({});
  const [seoSettingsData, setSeoSettingsData] = useState({});
  const [selectedCategoryId, setSelectedCategoryId] = useState(null);
  const [stylesData, setStylesData] = useState({});


  useEffect(() => {
    AOS.init({
      once: true,
    });
  }, []);


  useEffect(() => {
    const fetchData = async (pageName) => {
      try {
        setLoading(true);
        const apiUrl = `${url}${apiVersion}/content-by-page/${pageName}`;
        const response = await fetch(apiUrl);
        const data = await response.json();
        const navbar = data?.data?.navbar?.data || [];
        const footer = data?.data?.footer || '';
        const content = data?.data?.content || '';
        const innerPage = data?.data?.inner_page || '';
        const translations = data?.data?.translations || '';
        const styles = data?.data?.styles || '';
        const seoSettings = data?.data?.['seo-settings'] || '';
        setNavbarData(navbar);
        setFooterData(footer);
        setContentData(content);
        setInnerPageData(innerPage);
        setTranslationsData(translations);
        setSeoSettingsData(seoSettings);
        setStylesData(styles);
        setLogos(data?.data?.logos || '');
        setLoading(false);
      } catch (error) {
        // console.log(error);
        setLoading(false);
      }
    };

    if (['', 'home', undefined].includes(pageName)) {
      pageName = 'home';
    } else if (pageUrl.includes('services/')) {
      let serviceId = pageUrl.substr(lastWordIndex);
      pageName = `services?service_id=${serviceId}`;
    } else if (pageUrl.includes('students/')) {
      let studentId = pageUrl.substr(lastWordIndex);
      pageName = `students?type_id=${studentId}`;
    } else if (pageUrl.includes('countries/')) {
      let countrytId = pageUrl.substr(lastWordIndex);
      pageName = `countries?country_id=${countrytId}`;
    } else if (pageUrl.includes('educations/')) {
      let eductionId = pageUrl.substr(lastWordIndex);
      pageName = `educations?type_id=${eductionId}`;
    }
    fetchData(pageName);
  }, [pageName]);

  useEffect(() => {
    const updateFavicon = () => {
      const link = document.querySelector('link[rel="icon"]');
      link.setAttribute('href', `${url + logos.logo_favicon}`);
    };

    const updateTitle = () => {
      const mainTitle = `${seoSettingsData?.main_title || ''} ${seoSettingsData?.separator || ''} ${
        innerPageData?.name || ''
      }`;
      let formattedTitle = '';
    
      formattedTitle = mainTitle.trim() || 'BrightMind';
    
      document.title = formattedTitle;
    };

    updateFavicon();
    updateTitle();
  }, [logos, pageName, seoSettingsData, innerPageData]);

  


  return (
    <BrowserRouter>
    <div className="App">
      {loading ?  (
            <div class="loading">
              <h2 className='loading-letters'>
                  <span>B</span>
                  <span>R</span>
                  <span>I</span>
                  <span>G</span>
                  <span>H</span>
                  <span>T</span>
                  <span>M</span>
                  <span>I</span>
                  <span>N</span>
                  <span>D</span>
                  <span>.</span>
                  <span>.</span>
                </h2>
            </div>
          ) : (
          <>
        <Navpages
            logos={logos}
            navbarData={navbarData}
            url={url}
        />
  
          <Routes>
            <Route index element = { 
              <Home 
                innerPageData={innerPageData}
                contentData={contentData}
                url={url}
                translationsData={translationsData}
              /> 
            }/>  
            <Route path='/home' element = { 
              <Home 
                innerPageData={innerPageData}
                contentData={contentData}
                url={url}
                translationsData={translationsData}
              /> 
            }/>  
            <Route path='/educations' element = { 
              <Educations 
                innerPageData={innerPageData}
                contentData={contentData}
                url={url}
                translationsData={translationsData}
                limit={limit}
                apiVersion={apiVersion}
               
              /> 
            }/>  
            <Route path='/educations/:id' element = { 
              <EducationsDetails 
                innerPageData={innerPageData}
                contentData={contentData}
                url={url}
                translationsData={translationsData}
              /> 
            } />
            <Route path='/services' element = { 
              <Services 
                innerPageData={innerPageData}
                contentData={contentData}
                url={url}
                translationsData={translationsData}
                limit={limit}
                apiVersion={apiVersion}
               
              /> 
            }/>  
             <Route path='/services/:id' element = { 
              <ServicesDetails 
                innerPageData={innerPageData}
                contentData={contentData}
                url={url}
                translationsData={translationsData}
              /> 
            } />
            <Route path='/students' element = { 
              <Students 
                innerPageData={innerPageData}
                contentData={contentData}
                url={url}
                translationsData={translationsData}
                limit={limit}
                apiVersion={apiVersion}
               
              /> 
            }/>  
            <Route path='/students/:id' element = { 
              <StudentsDetails 
                innerPageData={innerPageData}
                contentData={contentData}
                url={url}
                translationsData={translationsData}
              /> 
            }/>  
            <Route path='/countries' element = { 
              <Countries 
                innerPageData={innerPageData}
                contentData={contentData}
                url={url}
                translationsData={translationsData}
                limit={limit}
                apiVersion={apiVersion}
              /> 
            }/>  
            <Route path='/countries/:id' element = { 
              <CountriesDetails 
                innerPageData={innerPageData}
                contentData={contentData}
                url={url}
                translationsData={translationsData}
               
            
              /> 
            } />
            <Route path='/about' element = { 
              <About 
                innerPageData={innerPageData}
                contentData={contentData}
                url={url}
                translationsData={translationsData}
              /> 
            }/>  
            <Route path='/contact' element = { 
              <Contactus 
                innerPageData={innerPageData}
                contentData={contentData}
                url={url}
                translationsData={translationsData}
              /> 
            }/>  
          </Routes>

          <Footer
            footerData={footerData} 
            logos={logos} 
            url={url} 
            translationsData={translationsData} 
            contentData={contentData} 
          />
        </>
      )}
    </div>
    </BrowserRouter>
  );
}

export default App;
