import React from 'react'
import "./Apply.css"
import { Col, Container, Row } from 'react-bootstrap'
import { Paragraph, Title, TitleSection } from '../../../../components'
import flag from "../../../../assets/images/home/flag.png"
import loading from "../../../../assets/images/svg/photos-icon.psd.svg"

const Apply = ({ contentData, url, translationsData }) => {
  return (
    <div id='apply-section'>
      <Container className='section-border section-margin'>
        <Row>
          <Col xl={7} lg={9}>
            <Title title={translationsData.apply_now} />
            <TitleSection title={contentData.application_title} />
          </Col>
        </Row>
        <Paragraph
          para={contentData.application_description}
        />
        <Row className='apply-contact'>
          <Col lg={6}>
            <form className='apply-form'>
              <div className="form-content">
                <p className='form-title'>Full name</p>
                <input className='form-input' type='text' placeholder='your name' />
              </div>
              <div className="form-content">
                <p className='form-title'>Email address</p>
                <input className='form-input' type='text' placeholder='yourName@email.com' />
              </div>
              <div className="form-content phone-content">
                <p className='form-title'>Phone number</p>
                <Row className='phone-container'>
                  <Col sm={3} xs={3} className='form-input-phone'>
                    <select className='form-input-select' id='form-input-phone'>
                      <option>+1</option>
                      <option>+1</option>
                      <option>+1</option>
                    </select>
                  </Col>
                  <Col sm={9} xs={9} className='form-input-number'>
                    <input className='form-input' id='form-input-number' type='text' placeholder='123 456789' />
                  </Col>
                </Row>
              </div>
              <div className="form-content">
                <p className='form-title'>Location</p>
                <select className='form-input-select'><option>Select Location</option></select>
              </div>
              <div className="form-content">
                <p className='form-title'>All areas of study<span>(Optional)</span></p>
                <select className='form-input-select'><option>All level of study</option></select>
              </div>
              <div className="form-content">
                <p className='form-title'>All level of study</p>
                <select className='form-input-select'><option>All level of study</option></select>
              </div>
              <div className="form-content">
                <p className='form-title'>All languages of study</p>
                <select className='form-input-select'><option>All languages of study</option></select>
              </div>
              <div
                className="form-content"
                data-aos="fade-up"
                data-aos-duration="1800"
                >
                    <button className="contact-submit" type="submit">
                      {translationsData.apply_now}
                    </button>
                </div>
            </form>
          </Col>
          <Col lg={6}>
            <div className="apply-image">
              <img className='apply-img' src={contentData.application_image ? url + contentData.application_image : loading} />
            </div>
          </Col>
        </Row>

      </Container>
    </div>
  )
}

export default Apply