import React from 'react'
import "./About.css"
import { Container, Row, Col } from 'react-bootstrap'
import { Header } from '../../components'

const About = ({ innerPageData, url, translationsData }) => {
    return (
        <>
        <Header title={translationsData.name} image={url + innerPageData.image} />
       <Container className="details-container">
           <Row>
            <p className="about-descripton" dangerouslySetInnerHTML={{ __html: innerPageData.description }}></p>
           </Row>
       </Container>
   </>
    )
}

export default About
