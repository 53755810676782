import React from 'react'
import { Contact } from '../../components'
import { Header } from '../../components'

const Contactus = ({ contentData, url, translationsData, innerPageData }) => {
    return (
        <div>
              <Header title={translationsData.contact_us} image={url + innerPageData.image} />
              <Contact
              innerPageData={innerPageData}
              contentData={contentData}
              url={url}
              translationsData={translationsData}
               />
          
        </div>
    )
}

export default Contactus
