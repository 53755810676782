import React from 'react'
import "./Home.css"
import { Hero, Students, Why, Apply, Types, Services } from '../../features/Home/index'
import { Contact } from '../../components'

const Home = ({ contentData, url, innerPageData, translationsData }) => {
    return (
        <div>
            <Hero contentData={contentData} url={url} innerPageData={innerPageData} />
            <Services contentData={contentData} url={url} translationsData={translationsData} />
            <Why contentData={contentData} url={url} />
            <Apply contentData={contentData} url={url} translationsData={translationsData} />
            <Types contentData={contentData} url={url} translationsData={translationsData} />
            <Students contentData={contentData} url={url} translationsData={translationsData}/>
            <Contact contentData={contentData} url={url} translationsData={translationsData} />\
        </div>
    )
}

export default Home