import React from 'react';
import "./TitleSection.css";

const TitleSection = (props) => {
  const titleWords = props.title.split(' ');
  const lastWord = titleWords.pop();
  const titleWithoutLastWord = titleWords.join(' ');

  return (
    <div>
      <h1 className='title-section'>{titleWithoutLastWord} <span>{lastWord}</span></h1>
    </div>
  );
};

export default TitleSection;