import React from 'react'
import "./Hero.css"
import { Col, Container, Row } from 'react-bootstrap'
import '@splidejs/react-splide/css';
import { Splide, SplideSlide } from '@splidejs/react-splide';

const Hero = ({ contentData, url, innerPageData }) => {

  const heroSliderData = contentData.hero_slider || [];

  return (
    <div className='hero'>
      <video autoPlay muted loop className='hero-video'>
        <source src={url + innerPageData.image}></source>
      </video>
      <Container>
        <Row className='justify-content-center'>
          <Col xl={7} md={9} xs={11}>
            <Splide
              options = { {
                rewind: true,
                pagination: true,
              } }
            >
            

                {
                  heroSliderData.map((item, index) => (
                    <SplideSlide key={index}>
                      <h1 className='hero-title'>{item.title}</h1>
                      <p className="hero-para">
                        {item.description} 
                      </p>
                      <a href={`${item.button_url}`} className="apply">{item.button_label}</a>
                    </SplideSlide>
                    
                  ))
                }
           
            </Splide>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default Hero