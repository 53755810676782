import React from 'react'
import "./Header.css"
import { Container } from 'react-bootstrap'

const Header = ({ title, image }) => {
    return (
        <div className='header'>
            <img className='header-img' src={image} />
            <Container>
                <h1 data-aos="fade-right" data-aos-duration="1500" className='header-title'>{title}</h1>
            </Container>
        </div>
    )
}

export default Header
