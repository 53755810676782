import React from 'react'
import "./Contact.css"
import { Title, TitleSection, Paragraph } from "../index"
import { Container, Row, Col, Form } from 'react-bootstrap'
import loading from "../../assets/images/svg/photos-icon.psd.svg"

const Contact = ({ contentData, translationsData, url }) => {
    return (
        <Container className="section-margin">
            <Title title = {translationsData.contact_us} />
            <TitleSection title = {contentData.contact_us_title} /> 
            <Paragraph para={contentData.contact_us_description} />
            <Row className="mt-5 contact-container">
                <Col lg={6}>
                    <Form>
                        <div
                        className="contact-field"
                        data-aos="fade-up"
                        data-aos-duration="700"
                        >
                            <p className="contact-para">
                                {translationsData.full_name}
                            </p>
                            <input className="contact-input" type="text" placeholder="Your Name" />
                        </div>
                        <div
                        className="contact-field"
                        data-aos="fade-up"
                        data-aos-duration="900"
                        >
                            <p className="contact-para">
                                {translationsData.address}
                            </p>
                            <input className="contact-input" type="text" placeholder="your Address" />
                        </div>
                        <div className="form-content phone-content">
                            <p className='form-title'>{translationsData.phone_number}</p>
                            <Row className='phone-container'>
                            <Col sm={3} xs={3} className='form-input-phone'>
                                <select className='form-input-select' id='form-input-phone'>
                                <option>+1</option>
                                <option>+1</option>
                                <option>+1</option>
                                </select>
                            </Col>
                            <Col sm={9} xs={9} className='form-input-number'>
                                <input className='form-input' id='form-input-number' type='text' placeholder='123 456789' />
                            </Col>
                            </Row>
                        </div>
                        <div
                        className="contact-field"
                        data-aos="fade-up"
                        data-aos-duration="1200"
                        >
                            <p className="contact-para">
                                {translationsData.email_address}
                            </p>
                            <input className="contact-input" type="text" placeholder="yourName@email.com" />
                        </div>
                        <div
                        className="contact-field"
                        data-aos="fade-up"
                        data-aos-duration="1500"
                        >
                            <p className="contact-para">
                                {translationsData.note}
                            </p>
                            <input className="contact-input note-input" type="textarea" />
                        </div>
                        <div
                        className="contact-field"
                        data-aos="fade-up"
                        data-aos-duration="1800"
                        >
                            <button className="contact-submit" type="submit">
                               {translationsData.apply_now}
                            </button>
                        </div>
                    </Form>
                </Col>
                <Col lg={6}>
                    <div className="contact-image">
                        <img className="contact-img" src={contentData.contact_us_image ? url + contentData.contact_us_image : loading} />
                    </div>
                </Col>
            </Row>
        </Container>
    )
}

export default Contact
