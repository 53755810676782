import React from 'react'
import "./Students.css"
import { Paragraph, Title, TitleSection, Card } from "../../../../components/index"
import { Col, Container, Row } from 'react-bootstrap'
import loading from "../../../../assets/images/svg/photos-icon.psd.svg"


const Students = ({ contentData, translationsData, url }) => {

  const studentsData = contentData.student_types || [];

  return (
    <div className='students section-margin'>
        <Container>
            <Row>
                <Col xl={6}>
                    <Title title = {translationsData.apply_now} />
                    <TitleSection title = {contentData.apply_for_education_title}  />
                </Col>
            </Row>
            <Row>
                <Col lg={11}>
                    <Paragraph para = {contentData.apply_for_education_description} />
                </Col>
            </Row>
            <Row className="cards">

              {
                studentsData.map((item, index) => (
                  <Col md={6} key={index}>
                    <a href={`/students/${item.id}`}>
                      <Card 
                        image = {item.image ? url + item.image : loading} 
                        title = {item.name} 
                        para = {item.description_short}
                      />
                    </a>
                  </Col>
                    
                ))
              }
             
            </Row>
        </Container>
    </div>
  )
}

export default Students