import React, { useState, useEffect } from 'react'
import "./Services.css"
import { Details, Header, Paginations } from '../../components';
import { Container, Row, Col } from 'react-bootstrap';
import axios from 'axios';
import loading from "../../assets/images/svg/photos-icon.psd.svg"

const Services = ({ contentData, innerPageData, translationsData, url, limit, apiVersion }) => {
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [servicesData, setServicesData] = useState([]);
  
    useEffect(() => {
      const currentPage = contentData?.pagination?.metadata?.currentPage || 1;
      const totalPages = contentData?.pagination?.metadata?.totalPages || 0;
      const servicesData = contentData?.data || [];
    
      setCurrentPage(currentPage);
      setTotalPages(totalPages);
      setServicesData(servicesData);
    
      fetchDataByPage(currentPage);
    }, []);
  
  
    const fetchDataByPage = async (page) => {
      const startIndex = (page - 1) * limit;
      const apiURL = `${url}${apiVersion}/content-by-page/services?start=${startIndex}&limit=${limit}`;
  
      try {
        const response = await axios.get(apiURL);
        const { data, pagination } = response?.data?.data?.content;
        setServicesData(data);
        setTotalPages(pagination?.metadata?.totalPages);
      } catch (error) {
        console.log('Error fetching services:', error);
        // Handle the error condition accordingly
      }
    };
  
    const handlePageChange = (page) => {
      setCurrentPage(page);
      fetchDataByPage(page);
    };
    return (
      <div>
        <Header title={innerPageData.name} image={url + innerPageData.image} />
  
        <Container className="all-services-container mt-5">
          <Row>
            {servicesData.map((service) => (
              <Col lg={4} md={6} className="service-container" key={service.id}>
                <a
                  href={`/services/${service.id}`}
                >
                  <Details
                    image={service.image ? url + service.image : loading}
                    title={service.name}
                    para={service.description_short}
                  />
                </a>
              </Col>
            ))}
          </Row>
          <div className="d-flex justify-content-center mt-5">
            <Paginations currentPage={currentPage} totalPages={totalPages} onPageChange={handlePageChange} />
          </div>
        </Container>
      </div>
    )
}

export default Services
