import React from 'react'
import "./Why.css"
import { Container } from 'react-bootstrap'
import '@splidejs/react-splide/css';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import { CityCard } from "../../index"
import loading from "../../../../assets/images/svg/photos-icon.psd.svg"

const Why = ({ contentData, url }) => {

  const countriesData = contentData.countries || [];

  return (
    <div className='why-section'>
      <Container className='section-border section-margin'>
      </Container>
      <Splide 
            options = {{
              gap: "2rem",
              arrows: false,
              pagination: false,
              autoWidth: true,
              focus: "center",
              breakpoints: {
                768 : {
                  perPage: 1,
                },
              }
          }}
          
        >

          {
            countriesData.map((item, index) => (
              <SplideSlide key={index}>
                <a href={`/countries/${item.id}`}>
                  <CityCard 
                    para = {item.description_short} 
                    img = {item.image ? url + item.image : loading} 
                    title = {item.name} />
                </a>  
              </SplideSlide>
            ))
          }
       
         
        </Splide>
    </div>
  )
}

export default Why