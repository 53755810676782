import React from 'react'
import "./Card.css"
import { Col } from 'react-bootstrap'

const Card = ( props ) => {
  return (
    <div className='card-student'>
        <div className="card-student-image">
            <img className='card-student-img' src={props.image} />
        </div>
        <div className='card-student-footer'>
            <h1 className='card-student-title'>{props.title}</h1>
            <p className="card-student-para">{props.para}</p>
        </div>
    </div>
  )
}

export default Card