import React from 'react'
import "./Paragraph.css"

const Paragraph = ({ para }) => {
  return (
    <div  className="para" dangerouslySetInnerHTML={{ __html: para }} >
    </div>
   
  )
}

export default Paragraph