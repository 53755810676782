import React from 'react'
import "./CityCard.css"
import { Paragraph } from "../../../../components/index"
import { Col } from 'react-bootstrap'

const CityCard = ( props ) => {
  return (
    <div className='city-card'>
        <div className="city-image">
            <h1 className='city-img-ttile'>{props.title}</h1>
            <img className='city-img' src={props.img} />
        </div>
        <Paragraph para = {props.para} />
        <a className='city-link'>
            <p>Learn More</p>
            <svg width="22" height="8" viewBox="0 0 22 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M21.8536 4.35355C22.0488 4.15829 22.0488 3.84171 21.8536 3.64645L18.6716 0.464466C18.4763 0.269204 18.1597 0.269204 17.9645 0.464466C17.7692 0.659728 17.7692 0.976311 17.9645 1.17157L20.7929 4L17.9645 6.82843C17.7692 7.02369 17.7692 7.34027 17.9645 7.53553C18.1597 7.7308 18.4763 7.7308 18.6716 7.53553L21.8536 4.35355ZM0 4.5H21.5V3.5H0V4.5Z" fill="url(#paint0_linear_184_537)"/>
                <defs>
                <linearGradient id="paint0_linear_184_537" x1="13" y1="4" x2="25" y2="4" gradientUnits="userSpaceOnUse">
                <stop stop-color="#00ADEE"/>
                <stop offset="1" stop-color="#2E3092"/>
                </linearGradient>
                </defs>
            </svg>
        </a>
    </div>
  )
}

export default CityCard