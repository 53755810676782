import React from 'react'
import "./TypeCard.css"
import { Col } from 'react-bootstrap'

const TypeCard = (props) => {
    return (
        <div className="type-card">
            <img className='type-card-img' src={props.img} />
            <h1 className='type-card-title'>{props.title}</h1>
            <p className='type-card-para'>{props.para}</p>
            <a className='type-card-link' href='#'>
                <p>Learn More</p>
                <svg width="22" height="9" viewBox="0 0 22 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M21.8536 4.85355C22.0488 4.65829 22.0488 4.34171 21.8536 4.14645L18.6716 0.964466C18.4763 0.769204 18.1597 0.769204 17.9645 0.964466C17.7692 1.15973 17.7692 1.47631 17.9645 1.67157L20.7929 4.5L17.9645 7.32843C17.7692 7.52369 17.7692 7.84027 17.9645 8.03553C18.1597 8.2308 18.4763 8.2308 18.6716 8.03553L21.8536 4.85355ZM0 5H21.5V4H0V5Z" fill="url(#paint0_linear_184_422)" />
                    <defs>
                        <linearGradient id="paint0_linear_184_422" x1="13" y1="4.5" x2="25" y2="4.5" gradientUnits="userSpaceOnUse">
                            <stop stop-color="#00ADEE" />
                            <stop offset="1" stop-color="#2E3092" />
                        </linearGradient>
                    </defs>
                </svg>
            </a>
        </div>
    )
}

export default TypeCard