import React from 'react'
import "./Details.css"

const Details = ({ title, para, image }) => {
    return (
        <div className='alldetails' data-aos="zoom-in" data-aos-duration="1500">
            <div className='alldetails-img'>
                <img src={image} />
            </div>
            <div className='alldetails-title'>
                <h1>{title}</h1>
            </div>  
            <div className='alldetails-para'>
                <p>{para}</p>
            </div>
        </div>
    )
}

export default Details
